import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import Modal from 'react-modal';
import logo from './banner-site.jpg'; // Substitua pelo caminho do logo do evento

const BoiFestForm = () => {
  const [formData, setFormData] = useState({
    numeroConvite: '',
    nome: '',
    telefone: '',
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "numeroConvite") {
        // Permitir apenas números
        if (!/^\d*$/.test(value)) {
          return;
        }
      }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.numeroConvite || !formData.nome || !formData.telefone) {
      setModalMessage('Por favor, preencha todos os campos.');
      setModalIsOpen(true);
      return;
    }

    try {
      const response = await fetch('https://api.boifestmarilia.com.br/cadastro', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setModalMessage('Cadastro realizado com sucesso!');
        setFormData({ numeroConvite: '', nome: '', telefone: '' });
      } else {
        setModalMessage('Erro ao realizar o cadastro. Por favor, tente novamente.');
      }
    } catch (error) {
      console.error('Erro ao enviar dados:', error);
      setModalMessage('Erro ao realizar o cadastro. Por favor, tente novamente.');
    }

    setModalIsOpen(true);
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-4">
      <img src={logo} alt="Logo do evento" className="w-200 mb-8" />
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <form onSubmit={handleSubmit}>
          <div className="mb-6">
                <font size="6"><b>Cadastre-se para o sorteio de prêmios</b></font>
            </div>
          <div className="mb-4">
            <label htmlFor="numeroConvite" className="block text-black font-bold mb-2">Número do Convite</label>
            <input
                type="text"
                id="numeroConvite"
                name="numeroConvite"
                value={formData.numeroConvite}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded"
                required
                inputMode="numeric"
                pattern="[0-9]*"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="nome" className="block text-black font-bold mb-2">Nome Completo</label>
            <input
              type="text"
              id="nome"
              name="nome"
              value={formData.nome}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="telefone" className="block text-black font-bold mb-2">Telefone Celular</label>
            <InputMask
              mask="(99) 99999-9999"
              value={formData.telefone}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            >
              {(inputProps) => <input {...inputProps} type="text" id="telefone" name="telefone" />}
            </InputMask>
          </div>
          <button type="submit" className="w-full bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600">
            Cadastrar
          </button>
        </form>
        <div className="mt-4 text-black">
          Dúvidas: <a href="mailto:contato@boifestmarilia.com.br" className="underline">contato@boifestmarilia.com.br</a>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Mensagem do sistema"
        className="flex items-center justify-center h-full w-full outline-none"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-50"
      >
        <div className="bg-white p-8 rounded-lg shadow-lg max-w-md mx-auto">
          <h2 className="text-xl font-bold mb-4">Aviso</h2>
          <p className="mb-4">{modalMessage}</p>
          <button
            onClick={() => setModalIsOpen(false)}
            className="w-full bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600"
          >
            Fechar
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default BoiFestForm;
