import React from 'react';
import { Helmet } from 'react-helmet';
import './App.css';
import BoiFestForm from './BoiFestForm'; // Certifique-se de ter criado o componente BoiFestForm

function App() {
  return (
    <div className="App min-h-screen flex items-center justify-center">
      <Helmet>
        <title>Boi Fest - Cadastro</title>
      </Helmet>
      <BoiFestForm />
    </div>
  );
}

export default App;